//import Reports from './pages/Reports';
//import ListReports from "./pages/Reports/ListReports";
//import ReportDetails from "./pages/Reports/ReportDetails";

export default {
    install: (app, options) => {

        /*options.router.addRoute({
            path: '/',
            redirect: '/reports'
        });
        options.router.addRoute({
            path: '/reports',
            name: 'Reports',
            component: Reports,
            children: [
                {
                    parent: 'Reports',
                    path: '',
                    redirect: '/reports/overview'
                }, {
                    parent: 'Reports',
                    path: 'overview',
                    name: 'ListReports',
                    component: ListReports
                }, {
                    parent: 'Reports',
                    path: 'details/:reportId',
                    name: 'ReportDetails',
                    component: ReportDetails,
                    props: true
                }
            ]
        });
        options.router.addRoute({ // somehow necessary
            path: '/reports/details/:reportId',
            name: 'ReportDetails',
            component: ReportDetails,
            parent: 'Reports',
            props: true
        });*/

        // For Ede, for debugging:
        // console.log(options.router.getRoutes());

        // const { t } = options.i18n.global;
        options.app.provide('menuItems', [
        /*{
            slug: 'reports',
            path: '/reports',
            name: 'Reports',
            label: t('menuItems.reports.linkLabel'),
            children: [
                {
                    path: '/reports/overview',
                    name: 'ListReports',
                    label: t('menuItems.reports.children.overview.linkLabel')
                }
            ]
        }*/
        ]);
    }
}
